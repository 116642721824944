import { CiLinkedin } from "react-icons/ci";
import { FaGitlab } from "react-icons/fa";
import { VscGithub } from "react-icons/vsc";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiWellfound } from "react-icons/si";
import "./App.css";

function App() {
  function toggleView() {
    if (window.scrollY > 0) {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    } else {
      const element = document.getElementById("more");
      element?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }
  return (
    <div className="app" onClick={toggleView}>
      <div id="paul" className="tile">
        <h1 className="header">Paul.</h1>
        <p className="content">Entrepreneur.</p>
        <p className="content">Developer.</p>
      </div>
      <div id="more" className="tile">
        <h1 className="header">Entrepreneur.</h1>
        <p className="content">
          <a
            href="https://linkedin.com/in/paul-peters-482773102"
            className="link"
            onClick={(e) => e.stopPropagation()}
          >
            <CiLinkedin size={30} />
          </a>{" "}
          <a
            href="https://x.com/packapaul"
            className="link"
            onClick={(e) => e.stopPropagation()}
          >
            <FaSquareXTwitter size={30} />
          </a>{" "}
          <a
            href="https://wellfound.com/projects/250022-yappo-app-next-gen-social-audio-community-on-the-app-store"
            className="link"
            onClick={(e) => e.stopPropagation()}
          >
            <SiWellfound size={30} />
          </a>
        </p>
        <h1 className="header">Developer.</h1>
        <p className="content">
          <a
            href="https://github.com/packapaul"
            className="link"
            onClick={(e) => e.stopPropagation()}
          >
            <VscGithub size={30} />
          </a>{" "}
          <a
            href="https://gitlab.com/packapaul/speedle"
            className="link"
            onClick={(e) => e.stopPropagation()}
          >
            <FaGitlab size={30} />
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
